.company-video {
  padding-top: 80px;

  .company-video-heading {
    margin-bottom: 49.49px;
  }

  .company-view {
    position: relative;
    border-radius: 80px;
    padding-bottom: 56.25%;
    height: 500px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 80px;
      height: 100%;
    }
  }
}

.react-player__preview {
  border-radius: 80px;
  width: 100%;

  button {
    position: absolute;
    height: 178px;
    width: 178px;
    border-radius: 50%;
    border: 9px solid #ffffff80;
    cursor: pointer;
    background-color: transparent;
  }
}

.team-members {
  padding-top: 130px;

  .mobile-view-teams-show {
    display: none;
  }

  .team-member-section-headline {
    margin-bottom: 80.33px;
  }

  .team-member-inner-content-wrapper-desktop {
    display: flex;

    .single-member-content {
      margin-right: 120px;
      position: relative;

      .member-img {
        position: relative;
        padding: 8px;
        width: 192px;
        height: 244px;

        .image-shape {
          position: absolute;
          left: 25px;
          top: 27px;
          z-index: -1;
        }

        img {
          border-radius: 28px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      .member-content {
        margin-top: 16px;
        padding: 0 10px;

        h5 {
          font-family: $primaryFontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22.4px;
        }

        span {
          display: inline-block;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18.2px;
          color: #0f0f0f99;
        }
      }
    }
  }

  .team-member-inner-content-wrapper {
    .single-member-content {
      margin-right: 120px;
      position: relative;

      .member-img {
        position: relative;
        padding: 8px;

        .image-shape {
          position: absolute;
          left: 25px;
          top: 27px;
          z-index: -1;
        }

        img {
          border-radius: 28px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      .member-content {
        margin-top: 16px;
        padding: 0 10px;

        h5 {
          font-family: $primaryFontFamily;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 22.4px;
        }

        span {
          display: inline-block;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 18.2px;
          color: rgba(15, 15, 15, 60%);
        }
      }
    }
  }
}

.download-app {
  padding-bottom: 81px;
  padding-top: 140px;

  .download-app-store {
    margin-top: 28px;
    margin-bottom: 60px;

    a {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .download-app-store-mobile-view {
    display: none;
  }

  .screenshoot {
    .single-img {
      margin-bottom: 24px;
      border-radius: 12px;

      img {
        width: 100%;
        border-radius: 18px;
      }
    }
  }

  .mobile-app-screen {
    display: none;
  }
}

.partner-section {
  padding-top: 140px;

  .partner-section-headline {
    margin-bottom: 80px;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 600px;
      margin: auto;
    }
  }

  .partner-carousel {
    .slick-slider {
      display: block;

      &.slick-initialized {
        overflow: hidden;
      }
    }

    .slick-initialized {
      .slick-slide {
        display: block;
      }
    }
  }
}

.not-found-page {
  padding-top: 170px;
  padding-bottom: 266px;

  .not-found-inner-content-wrapper {
    .not-found-animation {
      width: 379px;
      height: 379px;
      margin: auto;
    }

    .not-found-content {
      h4 {
        color: $primaryColor;
        font-size: 39px;
        line-height: 54.6px;
        font-weight: $fontWeightRegular;
        margin-bottom: 12px;
      }

      span {
        display: inline-block;
        color: $secondaryColor;
        font-size: 20px;
        line-height: 28px;

        a {
          color: $themeColor;
          font-weight: $fontWeightSemiBold;
        }
      }
    }
  }
}

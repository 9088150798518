html,
body {
  color: $primaryColor;
  font-size: $primaryFontSize;
  line-height: $primaryFontLineHeight;
  font-weight: $fontWeightRegular;
  font-family: $primaryFontFamily;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

p {
  margin: 0;
  color: $secondaryColor;
  font-size: $primaryFontSize;
  line-height: $primaryFontLineHeight;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: $secondaryFontFamily;
  color: $primaryColor;
}

h1 {
  font-size: 61px;
  line-height: 73.2px;
  font-weight: $fontWeightRegular;
  color: $primaryColor;
}

h2 {
  font-size: 49px;
  line-height: 58.8px;
  color: $primaryColor;
}

h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: $fontWeightMediumBold;
  color: $primaryColor;
}

h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: $fontWeightMediumBold;
  color: $primaryColor;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: $fontWeightMediumBold;
  color: $primaryColor;
}

h6 {
  font-size: 14px;
  line-height: 22px;
  font-weight: $fontWeightMediumBold;
  color: $primaryColor;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* stylelint-disable property-no-vendor-prefix */
a {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  outline: none !important;
  font-family: $primaryFontFamily;
  text-decoration: none;
}

/* stylelint-disable property-no-vendor-prefix */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: 0;
  box-shadow: none;
  background: none;
}

.theme-color {
  color: $themeColor !important;
}

.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s linear;
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.page-padding-top {
  padding-top: 80px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.cardHeight {
  height: $cardHeight;
}

.section-bg {
  background-color: $themeSecondaryColor;
}

.overflow-hidden {
  overflow: hidden;
}

.section-padding {
  padding: $sectionPadding;
}

.section-padding-bottom {
  padding-bottom: 65px;
}

.short-section-padding-top {
  padding-top: 30px;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-16 {
  margin-bottom: 16px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.error {
  font-size: 13px;
  font-weight: $fontWeightRegular;
  color: red !important;
  display: inline-block;
  line-height: 17px;
}

.success {
  font-size: 13px;
  font-weight: $fontWeightRegular;
  color: $themeColor !important;
  display: inline-block;
  line-height: 18.2px;
}

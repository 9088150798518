@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400,500,600,700,800,900&display=swap");
// colors
$whiteColor: #fff;
$blackColor: #000;
$primaryColor: #0f0f0f;
$secondaryColor: #646464;
$themeColor: #4eb747;
$hoverColor: #20611c;
$themePrimaryColor: #e4f2e4;
$themeSecondaryColor: #fcfcfc;

// fonts
$primaryFontFamily: "Raleway", sans-serif;
$secondaryFontFamily: "Cabin", sans-serif;
$fontWeightThink: 300;
$fontWeightRegular: 400;
$fontWeightMediumBold: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;

// font size
$primaryFontSize: 20px;
$secondaryFontSize: 16px;

// line height
$primaryFontLineHeight: 28px;
$secondaryFontLineHeight: 22.4px;

// border radius
$primaryBorderRadius: 28px;
$secondaryBorderRadius: 12px;
$smBorderRadius: 8px;

// padding
$sectionPadding: 65px 0;
$shortSectionPadding: 32px 0;

// height
$cardHeight: 284px;

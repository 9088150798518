/* ************** min width 1020px ************** */
@media (min-width: 1024px) and (max-width: 1199px) {
  .header-section {
    .menu-bar {
      ul {
        li {
          margin-right: 38px;

          &::after {
            right: -21px;
          }
        }
      }
    }
  }

  .team-members {
    .team-member-inner-content-wrapper-desktop {
      .single-member-content {
        margin-right: 20px;
      }
    }
  }

  /* *********** hero banner ************* */
  .hero-banner {
    .banner-middle {
      .hero-text {
        padding: 0;
      }

      .hero-banner-img {
        img {
          position: relative;
          top: 0;
        }

        &::after {
          height: 350px;
          width: 350px;
          left: 46%;
        }
      }
    }
  }

  .revolution-section {
    .rounded-shape {
      .revolution-img {
        height: auto;
        width: auto;
      }
    }
  }

  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 10px;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .cardHeight {
    height: 199px !important;
  }
}

@media (min-width: 911px) and (max-width: 950px) {
  .cardHeight {
    height: 140px !important;
  }
}

/* ************* min-width 768px **************** */
@media (min-width: 768px) and (max-width: 991px) {
  // hero banner
  .mobile-view-order-one {
    order: 2 !important;
  }

  .mobile-view-order-two {
    order: 1 !important;
  }

  .download-app {
    .download-app-store {
      a {
        margin-right: 30px !important;
      }
    }
  }

  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 10px;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .cardHeight {
    height: 140px !important;
  }
}

/* ************** min width 540px *************** */
@media (min-width: 540px) and (max-width: 600px) {
  .mobile-app-screen {
    .single-img {
      img {
        width: 100%;
      }
    }
  }

  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 10px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .download-app {
    .download-app-store {
      a {
        display: block !important;
      }
    }
  }
}

/* ********* min width 99px ******************** */
@media (min-width: 992px) and (max-width: 1199px) {
  /* ******* global ****** */
  .cardHeight {
    height: auto;
  }

  /* ******* footer ******** */
  .footer-section {
    .subscription {
      .submit-button {
        width: auto;
      }
    }
  }
}

/* *********** min width 320px ************* */
@media (min-width: 320px) and (max-width: 767px) {
  .mobile-mb {
    margin-bottom: 16px;
  }

  .mobile-app-screen {
    display: block !important;
  }

  .mobile-card-height {
    img {
      height: 260px !important;
    }
  }

  .screenshoot {
    display: none;
  }

  .language-translate-area ul {
    left: 0;
  }

  .registered-img-height {
    img {
      min-height: 175px;
    }
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  /* *********** global heading *********** */
  h1 {
    font-size: 30px;
    line-height: 45.6px;
  }

  h2 {
    font-size: 25px;
    line-height: 35.4px;
  }

  .section-padding {
    padding: 30px 0;
  }

  .section-headline-content {
    margin-bottom: 40px;
  }

  .mobile-view-order-one {
    order: 1;
  }

  .mobile-view-order-two {
    order: 2;
  }

  .cardHeight {
    height: auto;
  }

  /* *********** mobile menu ********** */
  .menu-bar {
    display: none;
  }

  .mobile-menu-item {
    position: absolute;
    right: 16px !important;
    top: 33px;
    display: block;
  }

  .bm-overlay {
    background-color: #000 !important;
  }

  .language-translate-area {
    ul {
      top: 410px;
      background-color: transparent;
      box-shadow: none;
      padding: 0 20px;
      width: 100%;
      margin-left: 20px;

      li {
        line-height: 40px !important;
        margin: 0 !important;
        padding: 0 !important;

        button {
          padding: 0 !important;
        }
      }
    }
  }

  /* ************* header *********** */
  .header-section {
    border: none;
    padding: 20px 0;

    .logo {
      a {
        padding: 0;
      }
    }
  }

  /* *********** hero banner ************ */
  .hero-banner {
    padding-top: 63px;

    .banner-middle {
      .hero-banner-img {
        position: relative;
        top: -7px;

        img {
          position: relative;
          top: -3px;
        }

        &::after {
          height: 75%;
          width: 75%;
          top: 36px;
          left: 49%;
        }
      }

      .hero-text {
        padding: 0 14px;

        h1 {
          margin-top: 10px;
          margin-bottom: 16px;
        }

        p {
          width: 100%;
        }
      }
    }
  }

  /* ************ explore offering ************* */
  .explore-offerings {
    padding-top: 108px;
    padding-bottom: 56px;

    .explore-offering-heading {
      width: auto;

      h2 {
        margin-bottom: 16px;
        padding: 0 40px;
      }

      p {
        width: auto;
      }
    }

    &.section-padding {
      padding: 70px 0;
    }

    .explore-card-card {
      margin-bottom: 24px;
      display: block;
      padding: 32px 36px;

      .explore-card-content {
        margin-top: 10px;
      }
    }
  }

  /* ************* revolution ************ */
  .revolution-section {
    background-color: #f8fcf8;
    border-radius: 36px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 51px 28px;

    .rounded-shape {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      .revolution-img {
        margin: auto;
        display: block;
        text-align: center;
        height: 206px;
        width: 100%;
        margin-bottom: 40px;

        img {
          height: 100%;
          margin: auto;
        }
      }
    }
  }

  /* ************** partner ************* */
  .partner-section {
    padding-top: 80px;

    .partner-section-headline {
      margin-bottom: 60px;

      h2 {
        padding: 0 40px;
      }
    }

    .single-partner {
      padding: 0 30px;
    }
  }

  .partner-carousel {
    margin-top: 0;
  }

  /* **************  download app ************* */
  .download-app {
    padding-top: 90px;

    h2 {
      padding: 0 20px;
    }

    .download-app-headline {
      h2 {
        padding: 0 70px;
      }
    }

    .download-app-store {
      a {
        display: inline-block;
        margin-bottom: 15px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .download-app-store-mobile-view {
      display: block;
    }

    .section-headline-content {
      margin-bottom: 68px;
    }

    .mobile-app-screen {
      .single-img {
        img {
          border-radius: 12px;
        }
      }
    }
  }

  .download-app-store-desktop-view {
    display: none;
  }

  /* **************** footer ************* */
  .desktop-bottom-widget-content {
    display: none;
  }

  .footer-section {
    padding-top: 24px;

    .widget-wrapper {
      margin-top: 32px;
    }

    .footer-top-widget-content {
      margin-bottom: 27px;

      span {
        display: inline-block;
      }
    }

    .top-widget-wrapper {
      display: block;

      .social-media {
        float: none;

        .social-media-icon {
          ul {
            li {
              margin-right: 4px;
            }
          }
        }
      }
    }

    .mobile-bottom-widget-content {
      display: block;

      .bottom-widget-content {
        display: block;
        padding: 15px 0;
        border: none;
        text-align: left !important;

        .copy-right-text {
          margin-right: 0;
        }
      }
    }

    .subscription {
      form {
        display: flex;
        justify-content: space-between;
      }

      .submit-button {
        width: auto;
      }
    }

    .desktop-view-widget-show {
      display: none;
    }

    .mobile-view-widget-show {
      display: block;
    }
  }

  /* ********************************************
    about page
  ********************************************* */

  /* ********** team members ********* */
  .company-video {
    padding-top: 40px;

    .company-video-heading {
      margin-bottom: 40px;

      h2 {
        margin-bottom: 16px;
      }
    }

    .company-view {
      .video-shape {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100px;
        width: 100px;
        border-radius: 50%;
        border: 9px solid #ffffff80;
        cursor: pointer;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  /* ************* team members ************ */
  .team-members {
    padding-top: 80px;

    .desktop-view-team-show {
      display: none;
    }

    .mobile-view-teams-show {
      display: block;
    }

    .team-member-inner-content-wrapper {
      .single-member-content {
        margin-right: 0;

        .member-img {
          padding: 19px;

          .image-shape {
            position: absolute;
            left: 21px;
            top: 21px;
            z-index: -1;
            padding: 12px;
          }
        }

        .member-content {
          padding: 0 14px;
          margin-top: 0;
        }
      }
    }

    .team-member-section-headline {
      margin-bottom: 30px;
    }
  }

  /* ************ call to action ********* */
  .call-to-action {
    padding-top: 80px;
    padding-bottom: 97px;

    .call-to-action-inner-content {
      padding: 65px 28px 55px 28px;

      h2 {
        margin-bottom: 16px;
      }
    }
  }

  /* ************************************************
    contact page
  ************************************************* */
  .contact-page {
    padding-bottom: 119px;

    &.page-padding-top {
      padding-top: 40px;
    }

    .contact-headline {
      margin-bottom: 32px;
    }
  }

  /* ************************************************
    about us page
  ************************************************* */

  /* ************ why wingsfin ************** */
  .why-wingsfin {
    padding-top: 80px;

    .why-wingsfin-heading {
      margin-bottom: 40px;

      h2 {
        margin-bottom: 16px;
      }
    }
  }

  .why-wingsfin-inner-content {
    margin-bottom: 20px;
  }

  /* ***************************************
  career page
  **************************************** */
  .career-page {
    padding-top: 40px;

    .form-section-headline {
      margin-bottom: 40px;

      h2 {
        margin-bottom: 16px;
      }

      p {
        width: auto;
      }
    }

    .form-wrapper {
      padding: 0;
      width: auto;

      .submit-btn-wrapper {
        button {
          width: 100%;
        }
      }
    }
  }

  // not found page
  .not-found-page {
    padding-bottom: 159px;
    padding-bottom: 333px;

    .not-found-inner-content-wrapper {
      .not-found-animation {
        width: 250px;
        height: 250px;
      }

      .not-found-content {
        h4 {
          padding: 0 75px;
          font-size: 20px;
          font-weight: $fontWeightRegular;
          line-height: 28px;
        }

        span {
          display: inline-block;
          color: $secondaryColor;
          font-size: 16px;
          line-height: 22.4px;

          a {
            color: $themeColor;
            font-weight: $fontWeightSemiBold;
          }
        }
      }
    }
  }

  /* *********** success message modal ************* */
  .success-modal-wrapper {
    .success-modal-inner-content {
      min-width: 300px;
      min-height: 300px;
      position: relative;

      .loading-message {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 145px;
          height: 82px;
        }
      }

      .success-message-wrapper {
        .success-content {
          min-width: 300px;
          bottom: 9px;
          padding: 17px 22px;
        }
      }
    }
  }

  /* ********************************************
  //terms and conditions page
********************************************* */
  .terms-condition-page {
    padding-bottom: 30px;

    &.page-padding-top {
      padding-top: 40px;
    }

    .terms-condition-headline {
      padding-bottom: 16px;
      margin-bottom: 24px;
    }
  }

  /* ********************************************
  //terms and conditions page
********************************************* */
  .privacy-policy-page {
    padding-bottom: 30px;

    &.page-padding-top {
      padding-top: 40px;
    }

    .privacy-policy-headline {
      padding-bottom: 16px;
      margin-bottom: 24px;
    }
  }

  /* ******************************************
    document page
  ******************************************* */
  .document-page {
    .document-tabs {
      .single-tab {
        padding: 10px 20px;

        h3 {
          font-size: 16px;
        }
      }
    }
    .request-form {
      .form-wrapper {
        padding: 0;
        width: auto;
      }

      .submit-btn-wrapper {
        button {
          width: 100%;
        }
      }
    }

    .document-headline {
      margin-bottom: 32px;

      h2 {
        margin-bottom: 16px;
      }

      p {
        a {
          display: inline-block;
          color: $themeColor;
          font-variant-numeric: lining-nums proportional-nums;
        }
      }
    }

    .documents-wrapper {
      .document-heading-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #0f0f0f29;
        margin-bottom: 24px;

        .title {
          h3 {
            color: $secondaryColor;
          }
        }

        .download-action {
          h3 {
            color: $secondaryColor;
          }
        }
      }

      .single-document {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(15, 15, 15, 0.16);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .download-item-name {
          display: flex;
          justify-content: flex-start;

          .pdf {
            flex-shrink: 0;
          }

          .text {
            span {
              display: inline-block;
              font-weight: $fontWeightRegular;
              font-size: $secondaryFontSize;
              line-height: $secondaryFontLineHeight;
              color: $secondaryColor;
            }
          }
        }

        .download-document {
          display: flex;
          justify-content: flex-start;

          .download-icon {
            flex-shrink: 0;
          }

          .dowinload-text {
            display: none;
          }
        }
      }
    }
  }
}

/* *********** min width 430px  *************** */
@media (min-width: 430px) and (max-width: 767px) {
  h1 {
    font-size: 39px;
    font-weight: $fontWeightRegular;
    line-height: 54.6px;
  }

  h2 {
    font-size: 39px;
    line-height: 54.6px;
    font-weight: $fontWeightRegular;
  }

  .section-headline-content {
    h2 {
      margin-bottom: 16px;
    }
  }

  /* ************* hero banner ************ */
  .hero-banner {
    .hero-text {
      h1 {
        margin-bottom: 16px;
      }
    }
  }

  /* ********** explore offering************* */
  .explore-offerings {
    padding-top: 108px;
    padding-bottom: 80px;

    .explore-offering-heading {
      h2 {
        padding: 0 18px;
      }
    }

    .explore-card-card {
      border-radius: 28px;
      padding: 32px 36px;
      margin-bottom: 24px;
    }
  }

  /* **************** revolution area  ************** */
  .revolution-section {
    border-radius: 36px;
    padding: 52px 28px;

    .revolution-content {
      h2 {
        margin-bottom: 16px;
      }
    }

    .revolution-img {
      width: 269px;
      margin-bottom: 40px;
    }
  }

  /* ************ partner section *************** */
  .partner-section {
    padding-top: 80px;
  }

  .footer-section {
    .top-widget-wrapper {
      .social-media {
        .social-media-icon {
          ul {
            li {
              margin-right: 24px;
            }
          }
        }
      }
    }
  }
}

// for iphone xR
@media (min-width: 414px) and (max-width: 415px) {
  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0 60px;
      }
    }
  }

  .download-app {
    .download-app-headline {
      h2 {
        padding: 0 80px;
      }
    }
  }

  /* *********** about page ************** */
  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 60px;
      }
    }
  }

  /* ********* not found page ************ */
  // not found page
  .not-found-page {
    .not-found-inner-content-wrapper {
      .not-found-content {
        h4 {
          padding: 0 100px;
        }
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 380px) {
  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 50px;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 370px) {
  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 40px;
      }
    }
  }
}

@media (min-width: 390px) and (max-width: 400px) {
  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 50px;
      }
    }
  }
}

@media (min-width: 319px) and (max-width: 350px) {
  .registered-img-height {
    img {
      min-height: 162px;
    }
  }

  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0 47px;
      }
    }
  }
}

@media (min-width: 390px) and (max-width: 391px) {
  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0 47px;
      }
    }
  }

  // not found page
  .not-found-page {
    .not-found-inner-content-wrapper {
      .not-found-content {
        h4 {
          padding: 0 90px;
        }
      }
    }
  }
}

@media (min-width: 430px) and (max-width: 431px) {
  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0;
      }
    }
  }

  // not found page
  .not-found-page {
    .not-found-inner-content-wrapper {
      .not-found-content {
        h4 {
          padding: 0 100px !important;
        }
      }
    }
  }

  .download-app {
    .download-app-headline {
      h2 {
        padding: 0 52px;
      }
    }
  }
}

@media (min-width: 344px) and (max-width: 345px) {
  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0 0;
      }
    }
  }

  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 10px;
        max-width: 264px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .not-found-page {
    .not-found-inner-content-wrapper {
      .not-found-content {
        h4 {
          padding: 0 70px;
        }
      }
    }
  }
}

@media (min-width: 412px) and (max-width: 415px) {
  .partner-section {
    .partner-section-headline {
      h2 {
        padding: 0 57px;
      }
    }
  }

  .download-app {
    .download-app-headline {
      h2 {
        padding: 0 77px;
      }
    }
  }

  .company-video {
    .company-video-heading {
      h2 {
        padding: 0 60px;
      }
    }
  }

  .not-found-page {
    .not-found-inner-content-wrapper {
      .not-found-content {
        h4 {
          padding: 0 100px;
        }
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 550px) {
  .download-app {
    .download-app-headline {
      h2 {
        padding: 0 75px;
      }
    }
  }
}

.hero-banner {
  padding-top: 110px;
  padding-bottom: 0;

  .hero-text {
    padding: 0 64px;

    h1 {
      margin-bottom: 24px;
    }

    p {
      width: 77%;
    }

    a {
      display: inline-block;
      margin-top: 40px;
    }
  }

  .banner-middle {
    display: flex;
    align-items: center;

    .hero-banner-img {
      position: relative;

      img {
        position: relative;
        top: -41px;
      }

      &::after {
        content: "";
        position: absolute;
        height: 444px;
        width: 444px;
        background: #4eb747;
        border-radius: 50%;
        z-index: -1;
        left: 47%;
        transform: translateX(-50%);
        top: 10px;
      }
    }
  }
}

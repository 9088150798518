.success-modal-wrapper {
  .success-modal-inner-content {
    min-width: 650px;
    min-height: 340px;
    position: relative;

    .loading-message {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 145px;
        height: 82px;
      }
    }

    .success-message-wrapper {
      .success-img {
        width: 210px;
        height: 210px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: 21px;

        img {
          height: 100%;
          width: 100%;
        }

        .round-success {
          width: 114px;
          height: 114px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .success-content {
        text-align: center;
        min-width: 457px;
        margin: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 110px;

        span {
          display: inline-block;
          color: $primaryColor;
          font-size: 20px;
          font-weight: $fontWeightRegular;
          line-height: 28px;
        }
      }
    }
  }
}

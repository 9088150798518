.why-wingsfin {
  padding-top: 126.52px;

  .why-wingsfin-heading {
    margin-bottom: 60px;
  }

  .why-wingsfin-inner-content {
    border-radius: 40px;
    border: 1px solid #e4f2e4;
    padding: 36px;
    background: #f8fcf8;
    padding-bottom: 20px;
    min-height: 243px;
    transition: all 0.3s linear;
    position: relative;
    top: 0;

    &.active-card {
      background-color: $themeColor;

      p {
        color: $whiteColor;
      }

      h3 {
        color: $whiteColor;
      }

      .high-light-content {
        span {
          color: $whiteColor;
        }
      }
    }

    &:hover {
      top: -5px;
      background-color: $themeColor;

      h3 {
        color: $whiteColor;
      }

      p {
        color: $whiteColor;
      }

      .high-light-content {
        span {
          color: $whiteColor;
        }
      }
    }

    h3 {
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px;
      min-height: 66px;
    }

    .high-light-content {
      text-align: right;
      margin-top: 12px;

      span {
        display: inline-block;
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: 72.8px;
      }
    }
  }
}

@import "./variables";

.primary-btn {
  color: $whiteColor;
  padding: 21px 54px 21px 56px;
  display: inline-block;
  font-weight: $fontWeightBold;
  font-size: $secondaryFontSize;
  line-height: $secondaryFontLineHeight;
  background-color: $themeColor;
  border-radius: $secondaryBorderRadius;
  transition: all 0.3s linear;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: none;

  &:hover {
    background-color: $hoverColor;
  }
}

.primary-btn-sm {
  color: $whiteColor;
  display: inline-block;
  font-weight: $fontWeightBold;
  font-size: 12px;
  line-height: $secondaryFontLineHeight;
  background-color: $themeColor;
  border-radius: $smBorderRadius;
  border: none;
  padding: 9px 15px 8px 14px;
}

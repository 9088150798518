.revolution-section {
  .rounded-shape {
    background-color: #f8fcf8;
    border-radius: 80px;
    padding: 150px 90px;

    .revolution-img {
      margin-top: 26px;
      width: 455px;
      height: 341px;
    }
  }

  .revolution-content {
    h2 {
      margin-bottom: 20px;
    }
  }
}

.react-responsive-modal-root {
  z-index: 9999999999999 !important;

  .react-responsive-modal-modal {
    border-radius: 32px !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;

    .react-responsive-modal-closeButton {
      display: none !important;
    }
  }
}

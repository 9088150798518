.mobile-menu-item {
  position: absolute;
  right: 20px !important;
  top: 20px;
  display: none;

  .cross {
    color: $themeColor;
    font-weight: $fontWeightBold;
  }

  .bm-overlay {
    position: static !important;
    width: 100%;
    background-color: #000;
  }

  .mobile-menu-item-wrapper {
    width: 100% !important;
    background-color: $whiteColor;
    top: 0 !important;

    .bm-item-list {
      padding: 0;

      .custom-select {
        padding-left: 0 !important;
        width: auto !important;
      }

      ul {
        li {
          line-height: 56px;
          font-size: 16px;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border-bottom: none;
          }

          &.language-translate {
            line-height: 1 !important;
          }

          a {
            font-size: 14px;
            font-weight: $fontWeightSemiBold;
            line-height: 50px;
            color: $primaryColor;
            display: inline-block;
            width: 100%;
          }
        }
      }
    }
  }
}

// new item
.menu-wrapper {
  margin-bottom: 32px;

  .flex-item {
    display: flex;
    justify-content: space-between;
    top: 0 !important;
    align-items: center;
    padding: 20px 16px;

    .cross-icon {
      text-align: right;
      position: relative;
      top: 0;

      span {
        display: inline-block;
        font-size: 30px;
        font-weight: $fontWeightMediumBold;
      }
    }
  }
}

.mobile-menu-item-list {
  padding: 0 20px;

  .language-translate-area {
    button {
      line-height: 0;
    }
  }
}

.explore-offerings {
  padding-bottom: 98px;
  padding-top: 130px;

  .explore-offering-heading {
    max-width: 892px;
    margin-left: auto;
    margin-right: auto;

    p {
      max-width: 600px;
      margin: auto;
    }
  }

  .explore-card-card {
    margin-bottom: 42px;
    display: flex;
    min-height: 210px;
    position: relative;
    top: 0;
    transition: all 0.3s linear;
    border-radius: 28px;
    border: 1px solid #e4f2e4;
    background: var(--White, #fff);
    box-shadow: 0 8px 24px 0 #00000005;
    padding: 36px 28px;

    &:hover {
      top: -5px;
    }

    .icon {
      margin-right: 18.5px;
      width: 56px;
      height: 56px;
      border-radius: 12px;
      background: #f6fbf6;
      text-align: center;
      padding: 14px;
    }

    .explore-card-content {
      h3 {
        margin-bottom: 15px;
      }

      ul {
        margin-left: 12px;

        li {
          font-size: 16px;
          line-height: 22.4px;
          position: relative;
          padding-left: 19px;
          font-weight: $fontWeightRegular;
          color: $secondaryColor;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            height: 5px;
            width: 5px;
            background-color: #838383;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

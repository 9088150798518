.header-section {
  border-bottom: 1px solid #0000001a;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999999;
  background: $whiteColor;

  .logo {
    width: 139px;
    height: 60px;

    a {
      display: inline-block;
      padding: 10px 0;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .menu-bar {
    text-align: right;

    ul {
      li {
        display: inline-block;
        margin-right: 102px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: -52px;
          height: 20px;
          width: 1px;
          background-color: #0f0f0f05;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }

        a {
          font-size: 14px;
          font-weight: $fontWeightSemiBold;
          line-height: 80px;
          color: $primaryColor;
          display: inline-block;
          transition: all 0.3s linear;

          &:hover {
            color: $themeColor;
          }
        }
      }
    }
  }
}

.mobile-menu {
  position: absolute;
  right: 0;
  top: 30px;
}

// language translator
.language-translate-area {
  > button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 80px;
    color: #0f0f0f;
    display: inline-block;
    transition: all 0.3s linear;
    position: relative;
    padding-right: 10px;
    border: none;
    background-color: transparent;
    padding-left: 0;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      background-image: url("../../../../../public/arrow.svg");
      background-repeat: no-repeat;
      right: -10px;
      height: 16px;
      width: 16px;
      transform: translateY(-50%);
      padding-right: 10px;
    }
  }

  ul {
    position: absolute;
    top: 70px;
    background: $whiteColor;
    border-radius: 24px;
    box-shadow: 0 4px 32px 0 #0f0f0f33;
    padding: 28px 32px;
    min-width: 244px;
    text-align: left;
    left: -150px;

    li {
      margin-right: 0 !important;
      display: block !important;
      padding: 20px 0;
      border-bottom: 1px solid #0f0f0f26;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &:after {
        display: none !important;
      }

      button {
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: $fontWeightSemiBold;
        text-transform: uppercase;
        line-height: 19.6px;
        transition: all 0.3s linear;

        &:hover {
          color: $themeColor;
        }
      }
    }
  }
}

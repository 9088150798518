.career-page {
  padding-top: 80px;
  padding-bottom: 113px;

  .form-section-headline {
    margin-bottom: 60px;

    p {
      width: calc(636px + 64px);
      margin: auto;
    }
  }

  .form-wrapper {
    width: 636px;
    margin: auto;

    form {
      border-radius: 10px;
      background: #f9f9fb;
      padding: 24px;

      .single-field {
        margin-bottom: 20px;

        label {
          display: block;
          width: 100%;
          color: #0f0f0f;
          font-size: 13px;
          font-weight: $fontWeightRegular;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          padding: 16px;
          border-radius: 10px;
          background-color: $whiteColor;
          border: none;
          color: $primaryColor;
          font-size: 13px;
          font-weight: $fontWeightRegular;
          font-variant-numeric: lining-nums proportional-nums;

          &::placeholder {
            color: #64646466;
          }
        }

        textarea {
          border: none;
          min-height: 125px;
          padding: 16px;
          width: 100%;
          background-color: $whiteColor;
          color: $primaryColor;
          font-size: 13px;
          font-weight: $fontWeightRegular;
          resize: none;
          border-radius: 10px;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: rgba(100, 100, 100, 40%);
          }
        }
      }
      .file-upload-wrapper {
        border: 1px dashed rgba(15, 15, 15, 0.15);
        background-color: $whiteColor;
        border-radius: 4px;
        padding: 24px;
        text-align: center;

        .custom-label-wrapper {
          display: inline-block;
        }

        .file-input {
          display: none;
        }

        .text-content {
          margin-bottom: 20px;
          margin-top: 8px;
          p {
            color: var(--text-body, #646464);
            font-size: 13px;
            font-weight: $fontWeightRegular;
            line-height: 18.2px;
            font-variant-numeric: lining-nums proportional-nums;
          }
        }

        .custom-file-label {
          display: inline-block;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #4eb747;
          color: #fff;
          padding: 0 6px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          transition: background-color 0.3s ease;
          position: relative;
          img {
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    .submit-btn-wrapper {
      margin: 4px 0;
    }
  }
}

input::placeholder {
  color: #64646466 !important;
}

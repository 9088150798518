.custom-select {
  border: none;
  padding: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border-radius: 4px;
  background-image: url("../../../../../public/images/arrow.png");
  background-repeat: no-repeat;
  background-position: right 0 center;
  cursor: pointer;
  font-size: 14px;
  font-weight: $fontWeightSemiBold;
  color: $primaryColor;
  padding-left: 0;

  &:focus {
    outline: none;
  }
}

.terms-condition-page {
  padding-bottom: 134px;

  .terms-condition-headline {
    padding-bottom: 40px;
    border-bottom: 1px solid #0f0f0f29;
    margin-bottom: 40px;

    h2 {
      margin-bottom: 0;
    }
  }

  .single-terms-condition {
    padding-bottom: 40px;

    h3 {
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      font-weight: $fontWeightRegular;
      line-height: 22.4px;
    }
  }
}

.contact-page {
  padding-bottom: 201px;

  .contact-info {
    background-color: #f9f9fb;
    border-radius: 18px;
    margin-bottom: 28px;
    padding: 24px 28px;
    position: relative;
    top: 0;
    transition: all 0.3s linear;

    &:hover {
      top: -5px;
    }

    h3 {
      margin-bottom: 12px;
    }

    .single-contact-info {
      margin-bottom: 12px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 12px;
        flex-shrink: 0;
      }

      .info {
        span {
          color: #0f0f0fcc;
          font-size: 13px;
          line-height: 18.2px;
          font-weight: $fontWeightRegular;
          display: inline-block;
          font-variant-numeric: lining-nums proportional-nums;
        }
      }
    }
  }

  .contact-headline {
    margin-bottom: 52px;
  }
}

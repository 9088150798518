.call-to-action {
  padding-bottom: 90px;
  padding-top: 135px;

  .call-to-action-inner-content {
    border-radius: 40px;
    border: 1px solid #e4f2e4;
    padding: 65px 0;
    background: #f8fcf8;

    h2 {
      margin-bottom: 20px;
    }

    a {
      display: inline-block;
      margin-top: 40px;
    }
  }
}

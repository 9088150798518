.document-page {
  padding-bottom: 146px;
  max-width: 654px;
  margin: auto;

  .document-headline {
    margin-bottom: 60px;

    p {
      a {
        display: inline-block;
        color: $themeColor;
        font-variant-numeric: lining-nums proportional-nums;
      }
    }
  }

  .document-tabs {
    background-color: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 32px;
    padding: 10px;

    .single-tab {
      display: inline-block;
      padding: 10px 32px;
      width: 50%;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;

      &.active {
        background-color: $themeColor;

        h3 {
          color: $whiteColor !important;
        }
      }
    }
  }

  .documents-wrapper {
    .document-heading-wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #0f0f0f29;
      margin-bottom: 24px;

      .title {
        h3 {
          color: $secondaryColor;
        }
      }

      .download-action {
        h3 {
          color: $secondaryColor;
        }
      }
    }

    .single-document {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(15 15 15 / 0.16);

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .download-item-name {
        display: flex;
        justify-content: flex-start;

        .pdf {
          flex-shrink: 0;
        }

        .text {
          span {
            display: inline-block;
            font-weight: $fontWeightRegular;
            font-size: $secondaryFontSize;
            line-height: $secondaryFontLineHeight;
            color: $secondaryColor;
          }
        }
      }

      .download-document {
        display: flex;
        justify-content: flex-start;

        .download-icon {
          flex-shrink: 0;
        }

        .dowinload-text {
          padding-left: 5px;

          span {
            display: inline-block;
            font-size: $secondaryFontSize;
            font-weight: $fontWeightSemiBold;
            line-height: $secondaryFontLineHeight;
            color: $themeColor;
          }
        }
      }
    }
  }

  .request-form {
    .form-wrapper {
      width: 636px;
      margin: auto;

      form {
        border-radius: 10px;
        background: #f9f9fb;
        padding: 24px;

        .single-field {
          margin-bottom: 20px;

          label {
            display: block;
            width: 100%;
            color: #0f0f0f;
            font-size: 13px;
            font-weight: $fontWeightRegular;
            margin-bottom: 8px;
          }

          input {
            width: 100%;
            padding: 16px;
            border-radius: 10px;
            background-color: $whiteColor;
            border: none;
            color: $primaryColor;
            font-size: 13px;
            font-weight: $fontWeightRegular;
            font-variant-numeric: lining-nums proportional-nums;

            &::placeholder {
              color: rgba(100, 100, 100, 0.4);
            }
          }

          textarea {
            &:focus {
              outline: none;
            }

            border: none;
            min-height: 125px;
            padding: 16px;
            width: 100%;
            background-color: $whiteColor;
            color: $primaryColor;
            font-size: 13px;
            font-weight: $fontWeightRegular;
            resize: none;
            border-radius: 10px;

            &::placeholder {
              color: rgba(100, 100, 100, 0.4);
            }
          }

          select {
            width: 100%;
            padding: 16px;
            border-radius: 10px;
            background-color: $whiteColor;
            border: none;
            color: #64646466;
            font-size: 13px;
            font-weight: $fontWeightRegular;
            font-variant-numeric: lining-nums proportional-nums;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: url("../../../images/select-svg.svg");
            background-repeat: no-repeat;
            background-position: right 16px center;

            &:focus {
              outline: none !important;
            }
          }

          .image-upload {
            text-align: center;
            padding: 24px;
            background-color: $whiteColor;
            border-radius: 4px;
            border: 1px dashed #0f0f0f26;

            span {
              color: #64646466;
              display: inline-block;
            }

            .upload-icon {
              margin-bottom: 8px;
            }

            .upload-text {
              span {
                display: inline-block;
                font-size: 13px;
                color: rgba(100, 100, 100, 0.4);
                line-height: 18.2px;
              }
            }

            .upload-wrapper {
              span {
                margin-top: 20px;
                font-size: 13px;
              }

              .upload-inner-content {
                button {
                  display: inline-block;
                  cursor: pointer;
                  border: none;
                  background-color: transparent;
                }
              }
            }

            .upload-button {
              margin-top: 20px;
              cursor: pointer !important;
              display: inline-block;
            }
          }
        }
        .file-upload-wrapper {
          border: 1px dashed rgba(15, 15, 15, 0.15);
          background-color: $whiteColor;
          border-radius: 4px;
          padding: 24px;
          text-align: center;

          .custom-label-wrapper {
            display: inline-block;
          }

          .file-input {
            display: none;
          }

          .text-content {
            margin-bottom: 20px;
            margin-top: 8px;
            p {
              color: var(--text-body, #646464);
              font-size: 13px;
              font-weight: $fontWeightRegular;
              line-height: 18.2px;
              font-variant-numeric: lining-nums proportional-nums;
            }
          }

          .custom-file-label {
            display: inline-block;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #4eb747;
            color: #fff;
            padding: 0 6px;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            transition: background-color 0.3s ease;
            position: relative;
            img {
              height: 30px;
              width: 30px;
            }
          }
        }
      }

      .submit-btn-wrapper {
        margin: 4px 0;
      }
    }
  }
}

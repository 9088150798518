.footer-section {
  padding-top: 20px;

  .top-widget-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0f0f0f26;
    padding-bottom: 21px;
    align-items: center;
  }

  .footer-top-widget-content {
    .widget-logo {
      max-width: 169px;
      max-height: 73px;

      img {
        height: auto;
      }
    }

    span {
      color: $secondaryColor;
      font-size: 10px;
      line-height: 14px;
      font-weight: $fontWeightMediumBold;
      display: inline-block;
    }
  }

  .social-media {
    display: flex;
    float: right;

    .social-media-text {
      margin-right: 28px;

      span {
        font-size: 13px;
        font-weight: $fontWeightRegular;
        line-height: 18.2px;
        color: $primaryColor;
      }
    }

    .social-media-icon {
      ul {
        li {
          display: inline-block;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .widget-wrapper {
    margin-top: 40px;

    .widget-title {
      margin-bottom: 12px;

      h4 {
        color: $themeColor;
      }
    }

    .widget-content {
      .widget-sub-content {
        margin-bottom: 16px;

        h5 {
          color: $primaryColor;
          font-size: 13px;
          font-weight: $fontWeightSemiBold;
          line-height: 18.2px;
        }

        span {
          color: $primaryColor;
          font-size: 13px;
          line-height: 18.2px;
          font-weight: $fontWeightRegular;
          display: block;
          font-variant-numeric: lining-nums proportional-nums;
        }

        ul {
          li {
            margin-bottom: 12px;

            a {
              font-size: 13px;
              font-weight: $fontWeightRegular;
              line-height: 18.2px;
              color: $primaryColor;
              transition: all 0.3s linear;
              display: inline-block;

              &:hover {
                color: $themeColor;
              }
            }
          }
        }
      }
    }
  }

  .bottom-widget-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #0f0f0f26;
    margin-top: 14px;
    padding-top: 16px;
    padding-bottom: 20px;

    .copy-right-text {
      margin-right: 20px;

      span {
        color: $primaryColor;
        line-height: 18.2px;
        font-weight: $fontWeightRegular;
        font-size: 13px;
        font-variant-numeric: lining-nums proportional-nums;
      }
    }

    .widget-link {
      ul {
        li {
          display: inline-block;
          margin-right: 15px;

          a {
            color: $primaryColor;
            line-height: 18.2px;
            font-weight: $fontWeightRegular;
            font-size: 13px;
            transition: all 0.3s linear;

            &:hover {
              color: $themeColor;
            }
          }
        }
      }
    }
  }

  .mobile-view-widget-show {
    display: none;
  }

  .subscription {
    margin-top: 40px;

    form {
      display: flex;
      justify-content: space-between;
    }

    .form-field {
      width: 60%;

      input {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #0f0f0f66;
        font-size: 13px;
        font-weight: $fontWeightRegular;
        line-height: 18.2px;
        padding-bottom: 16px;
        width: 100%;
      }
    }
  }

  .mobile-bottom-widget-content {
    display: none;
  }
}
